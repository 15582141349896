<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Fechamento de Caixa</h1>

      <v-row>
        <v-col sm="3" md="3">
          <v-row>
            <v-col sm="8" md="8">
              <v-row>
                <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedSelectedDate"
                      label="Data"
                      prepend-icon="mdi-calendar"
                      v-on="on"
                      v-bind="attrs"
                      readonly/>
                  </template>
                  <v-date-picker v-model="selectedDate" @input="formatarData()"/>
                </v-menu>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn @click.prevent="buscar" color="info" :loading="isLoading" @mouseover="visible = true">Buscar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col sm="7" md="7" v-if="hasResult">
          <v-col sm="10" md="10">
            <v-alert color="blue" dense outlined>
              <v-row class="card-title" justify="center">EXTRATO DE FECHAMENTO DIÁRIO</v-row>
              <v-row class="card-subtitle" justify="center">Data: {{ticketDate}}</v-row>
              <v-divider class="card-divider"/>
              <v-row><v-col class="card-col">Revendedores Ativos</v-col><v-col class="card-col">{{items.ag_ativas}}</v-col></v-row>
              <v-row><v-col class="card-col">Revendedores c/ Proposta</v-col><v-col class="card-col">{{items.ag_com_propostas}}</v-col></v-row>
              <v-row><v-col class="card-col">Revendedores s/ Proposta</v-col><v-col class="card-col">{{items.agencias_sem_propostas}}</v-col></v-row>
              <v-divider class="card-divider"/>
              <v-row><v-col :class="items.tickets_vendidos >= 0 ? 'card-col' : 'card-col card-negative'">Tickets Vendidos</v-col><v-col :class="items.tickets_vendidos >= 0 ? 'card-col' : 'card-col card-negative'">{{items.tickets_vendidos | currency}}</v-col></v-row>
              <v-row><v-col :class="items.creditos_consumidos >= 0 ? 'card-col' : 'card-col card-negative'">Créditos Consumidos</v-col><v-col :class="items.creditos_consumidos >= 0 ? 'card-col' : 'card-col card-negative'">{{items.creditos_consumidos | currency}}</v-col></v-row>
              <v-row><v-col class="card-col font-weight-black">Total de Vendas</v-col><v-col class="card-col">{{items.total_vendas | currency}}</v-col></v-row>
              <v-spacer class="card-spacer"/>
              <v-row><v-col :class="items.codigo_web >= 0 ? 'card-col' : 'card-col card-negative'">Códigos Web</v-col><v-col :class="items.codigo_web >= 0 ? 'card-col' : 'card-col card-negative'">{{items.codigo_web | currency}}</v-col></v-row>
              <v-row><v-col :class="items.total_codigo_web_consumido >= 0 ? 'card-col font-weight-black' : 'card-col font-weight-black card-negative'">Total de Códigos Web Consumidos</v-col><v-col :class="items.total_codigo_web_consumido >= 0 ? 'card-col' : 'card-col card-negative'">{{items.total_codigo_web_consumido | currency}}</v-col></v-row>
              <v-spacer class="card-spacer"/>
              <v-row><v-col :class="items.comissao_tickets >= 0 ? 'card-col' : 'card-col card-negative'">Comissão Tickets</v-col><v-col :class="items.comissao_tickets >= 0 ? 'card-col' : 'card-col card-negative'">{{items.comissao_tickets | currency}}</v-col></v-row>
              <v-row><v-col :class="items.comissao_credito_web >= 0 ? 'card-col' : 'card-col card-negative'">Comissão Web</v-col><v-col :class="items.comissao_credito_web >= 0 ? 'card-col' : 'card-col card-negative'">{{items.comissao_credito_web | currency}}</v-col></v-row>
              <v-row><v-col class="card-col font-weight-black">Total de Comissão</v-col><v-col class="card-col">{{items.total_comissao | currency}}</v-col></v-row>
              <v-spacer class="card-spacer"/>
              <v-row><v-col :class="items.premio_tickets >= 0 ? 'card-col' : 'card-col card-negative'">Prêmio Tickets</v-col><v-col :class="items.premio_tickets >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_tickets | currency}}</v-col></v-row>
              <v-row><v-col :class="items.premio_web_pago_revenda >= 0 ? 'card-col' : 'card-col card-negative'">Prêmio Web</v-col><v-col :class="items.premio_web_pago_revenda >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_web_pago_revenda | currency}}</v-col></v-row>
              <v-row><v-col class="card-col font-weight-black">Total Prêmio Pago</v-col><v-col class="card-col">{{items.total_premio_pago | currency}}</v-col></v-row>
              <v-spacer class="card-spacer"/>
              <v-row><v-col :class="items.lp >= 0 ? 'card-col' : 'card-col card-negative'">L.P.</v-col><v-col :class="items.lp >= 0 ? 'card-col' : 'card-col card-negative'">{{items.lp | currency}}</v-col></v-row>
              <v-row><v-col :class="items.deve_rua >= 0 ? 'card-col' : 'card-col card-negative'">D/R Rua tickets</v-col><v-col :class="items.deve_rua >= 0 ? 'card-col' : 'card-col card-negative'">{{items.deve_rua | currency}}</v-col></v-row>
              <v-divider class="card-divider"/>
              <v-row><v-col :class="items.pagamentos_tickets >= 0 ? 'card-col' : 'card-col card-negative'">Pagamentos Tickets</v-col><v-col :class="items.pagamentos_tickets >= 0 ? 'card-col' : 'card-col card-negative'">{{items.pagamentos_tickets | currency}}</v-col></v-row>
              <v-row><v-col class="card-col">Pagamento Web</v-col><v-col class="card-col">{{items.pagamento_web | currency}}</v-col></v-row>
              
              <v-divider class="card-divider"/>
                <v-row><v-col :class="items.premio_concedido_web >= 0 ? 'card-col' : 'card-col card-negative'">Prêmio Concedido WEB</v-col><v-col :class="items.premio_concedido_web >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_concedido_web | currency}}</v-col></v-row>
                <v-row><v-col :class="items.premio_concedido_revenda >= 0 ? 'card-col' : 'card-col card-negative'">Prêmio Concedido Revenda</v-col><v-col :class="items.premio_concedido_revenda >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_concedido_revenda | currency}}</v-col></v-row>

              <v-divider class="card-divider"/>

              <v-row><v-col :class="items.baixados_tickets >= 0 ? 'card-col' : 'card-col card-negative'">Baixados tickets</v-col><v-col :class="items.baixados_tickets >= 0 ? 'card-col' : 'card-col card-negative'">{{items.baixados_tickets | currency}}</v-col></v-row>
              <v-row><v-col :class="items.baixados_web >= 0 ? 'card-col' : 'card-col card-negative'">Baixados web</v-col><v-col :class="items.baixados_web >= 0 ? 'card-col' : 'card-col card-negative'">{{items.baixados_web | currency}}</v-col></v-row>
              <v-row><v-col :class="items.baixados_interior >= 0 ? 'card-col' : 'card-col card-negative'">Baixados interior</v-col><v-col :class="items.baixados_interior >= 0 ? 'card-col' : 'card-col card-negative'">{{items.baixados_interior | currency}}</v-col></v-row>
              <v-row><v-col :class="items.premio_web_retido_dia >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Web Retidos do Dia</v-col><v-col :class="items.premio_web_retido_dia >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_web_retido_dia | currency}}</v-col></v-row>
              <v-row><v-col :class="items.premio_web_retido >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Web Retidos</v-col><v-col :class="items.premio_web_retido >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_web_retido | currency}}</v-col></v-row>
              <v-row><v-col :class="items.premio_prescrito_ticket >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Prescritos Tickets</v-col><v-col :class="items.premio_prescrito_ticket >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_prescrito_ticket | currency}}</v-col></v-row>
              <v-row><v-col :class="items.premio_prescrito_web >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Web Prescritos</v-col><v-col :class="items.premio_prescrito_web >= 0 ? 'card-col' : 'card-col card-negative'">{{items.premio_prescrito_web | currency}}</v-col></v-row>

              <v-divider class="card-divider"/>

              <!-- Prêmios retidos  -->
              <v-expansion-panels>
                <v-expansion-panel @click="carregarTotais">
                  <v-expansion-panel-header>
                    Expandir o total de prêmios não baixados
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-container 
                      class="text-center"
                      v-if="isLoadingTotais"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      />
                    </v-container>
                    <v-container v-else>
                      <v-row><v-col :class="totais.premio_retido_total_interior >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Retidos Tickets (Prêmios não pago interiores)</v-col><v-col :class="totais.premio_retido_total_interior >= 0 ? 'card-col' : 'card-col card-negative'">{{totais.premio_retido_total_interior | currency}}</v-col></v-row>
                      <v-row><v-col :class="totais.premio_retido_total >= 0 ? 'card-col' : 'card-col card-negative'">Prêmios Altos Não Baixados Tickets</v-col><v-col :class="totais.premio_retido_total >= 0 ? 'card-col' : 'card-col card-negative'">{{totais.premio_retido_total | currency}}</v-col></v-row>
                      <v-row><v-col :class="totais.premio_web_retido_total >= 0 ? 'card-col' : 'card-col card-negative'">Premios Altos Não Baixados Web</v-col><v-col :class="totais.premio_web_retido_total >= 0 ? 'card-col' : 'card-col card-negative'">{{totais.premio_web_retido_total | currency}}</v-col></v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-alert>
          </v-col>
        </v-col>

        <v-col sm="9" md="9">
          <v-row v-if="!hasResult">
            <h1>Nenhum resultado para mostrar.</h1>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

const namespaceStore = 'fechamentoCaixa'

export default {
  name: 'FechamentoDeCaixa',
  mixins: [],
  data: () => ({
    ticketDate: null,
    selectedDate: moment(new Date()).format('YYYY-MM-DD'),
    formattedSelectedDate: moment(new Date()).format('DD/MM/YYYY'),
    isLoading: false,
    isLoadingTotais: false,
    isExpanded: false
  }),
  mounted () {},
  computed: {
    ...mapGetters(namespaceStore, ['items', 'hasResult', 'totais'])
  },
  methods: {
    ...mapActions(namespaceStore, ['submit', 'getPremiosRetidos']),
    buscar () {
      const self = this
      self.isLoading = true
      self.submit({ date: self.selectedDate }).then( async () => {
        self.ticketDate = moment(self.selectedDate).format('DD/MM/YYYY')
        self.isLoading = false
      }).catch(() => {
        self.isLoading = false
      })
    },
    formatarData () {
      const self = this
      self.formattedSelectedDate = moment(self.selectedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },

    carregarTotais () {
      this.isExpanded = !this.isExpanded

      if (this.isExpanded) {
        this.isLoadingTotais = true
        this.getPremiosRetidos({ date: this.selectedDate }).then( async () => {
          this.ticketDate = moment(this.selectedDate).format('DD/MM/YYYY')
        })
        .finally(() => {
          this.isLoading = false
          this.isLoadingTotais = false
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .card {
    &-title {
      font-size: x-large;
      text-transform: uppercase;
    }

    &-subtitle {
      font-size: large;
    }

    &-col {
      color: black;
      padding: 0px 10px;
    }

    &-divider {
      margin: 10px 0px;
      background-color: black;
    }

    &-spacer {
      margin: 10px 0px;
    }

    &-negative {
      color: red;
    }
  }
</style>